import React from "react"
import LeftIcon from "../../assets/chevron-left.inline.svg";


const BackButton = (props) => (
  <div {...props}>
      <LeftIcon/>
  </div>
)

export default BackButton
