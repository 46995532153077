import { Link } from "gatsby"
import RightNav from "./icons/rightNav"
import LeftNav from "./icons/leftNav"
import BackButton from "./icons/backButton"
import React, {useState} from "react"
import SeriesLink from "./seriesLink"


const ArtMenu = (props) => {
  const [open, setOpen] = useState(false)
  const {nextPath, previousPath, seriesSlug, seriesName, seriesData} = props


  return (
  <div className="fixed bottom-0 right-0">
    <div className="flex flex-col">

      <div>
        <div className={"menu-item flex flex-row items-center justify-end menu-item-navigating"}>
          {previousPath && (<LeftNav className="nav-icon left" url={previousPath}/>)}
          <SeriesLink slug={seriesSlug} linkText={seriesName}/>
          {nextPath && (<RightNav className="nav-icon right" url={nextPath}/>)}
        </div>
      </div>
      <div>
        <div role = "button" className={"menu-item flex flex-row items-center justify-en menu-item-inactive cursor-pointer" + (open ? "hidden" : "flex" )} onKeyDown={() => setOpen(!open)} onClick={() => setOpen(!open)}>BROWSE SERIES</div>
      </div>

      <div className={"max-w-md flex flex-row flex-wrap justify-end " + (open ? "flex" : "hidden" )}>
      {seriesData.map(series => (
        series.slug !== seriesSlug && (
        <div key={series.slug}>
          <div className={"menu-item flex flex-row items-center justify-end ml-1 menu-item-inactive"}>
            <SeriesLink slug={series.slug} linkText={series.name}/>
          </div>
        </div>
        )
        ))}
      </div>
      <div>
      <div className="flex flex-row items-center menu-item main">
        <Link className="hidden xs:flex pl-4 text-3xl" to="/">CHRISTOFFER JOERGENSEN</Link>
        <Link className="xs:hidden pl-4 text-3xl" to="/">C. JOERGENSEN</Link>
      </div>
      </div>
    </div>
  </div>
)}

export default ArtMenu
