import React from "react"
import { string } from 'prop-types'
import RightIcon from "../../assets/chevron-right.inline.svg"

const RightNav = ({ url, className }) => (
    <div className={className}>
        <a href={url} >
            <RightIcon/>
        </a>
    </div>
)

RightNav.propTypes = {
    url: string.isRequired,
    className: string,
};

export default RightNav
