import Layout from "../components/layout"
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import ArtMenu from "../components/artMenu"
import SEO from "../components/seo"


const Work = ({ data, pageContext }) => {
  const artwork = data.directusArtwork
  const { currentSeriesSlug, currentSeriesName, currentSeriesDescription, allseries, previousPath, nextPath, isMostRecent } = pageContext

return (
  <Layout>
    <div className="container w-full mx-auto lg:mt-10 mb-40">
      <SEO title={ artwork.name } />

      <div className="pb-16 md:pb-0 md:flex flex-row p-4">
        <div className="w-full">
          <Img fluid={artwork.image.localFile.childImageSharp.fluid} />
        </div>
        <div className="md:pl-4 w-4/5">
          <div className="font-medium mt-1">{ artwork.name }</div>
          <div>{ artwork.material }{artwork.material && artwork.size && (<span>, </span>)} { artwork.size }</div>
          <div className="my-8">
            {isMostRecent && (<div className="font-normal">Synopsis</div>)}
            <div className="" dangerouslySetInnerHTML={{ __html: isMostRecent ? currentSeriesDescription : '' }}/>
          </div>
        </div>

      </div>
      <ArtMenu previousPath={previousPath} nextPath={nextPath} seriesSlug={currentSeriesSlug} seriesName={currentSeriesName} seriesData={allseries}/>
    </div>
  </Layout>
) }

export const query = graphql`
  query($pageSlug: String!) {
    directusArtwork(slug: {eq: $pageSlug}) {
      name
      material
      size
      series {
        name
      }
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      creation_date(formatString: "DD.MM.YYYY")
    }
  }
`

export default Work
