import React from "react"
import { string } from 'prop-types'
import LeftIcon from "../../assets/chevron-left.inline.svg"

const LeftNav = ({ url, className }) => (
    <div className={className}>
      <a href={url}>
          <LeftIcon/>
      </a>
    </div>
)

LeftNav.propTypes = {
    url: string.isRequired,
    className: string,
};

export default LeftNav
