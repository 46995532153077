import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SeriesLink = (props) => {

  const {slug, linkText, ...other} = props
  const data = useStaticQuery(graphql`
   {
    site {
      siteMetadata {
        workpath
      }
    }
  }
  `)
  
  const path = data.site.siteMetadata.workpath + slug + "/most-recent"
  return <Link to={path} {...other}>{ linkText }</Link>
}

export default SeriesLink
